<script setup lang="ts">
const props = defineProps({
    blok: {
        type: Object,
        default: () => ({}),
    },
});

const imageClasses = computed(() => {
    const common = 'mb-2';
    if (!props.blok.iconAlignLeft) {
        return `${common} mx-auto`;
    } else return `${common} mx-auto md:ml-0`;
});
</script>

<template>
    <!-- Block start -->
    <div>
        <img :class="imageClasses" :src="blok.icon.filename" :alt="blok.icon.alt" />
        <h3 class="mb-2 text-xl font-bold dark:text-white">{{ blok.title }}</h3>
        <p class="mb-4 text-gray-500 dark:text-gray-400">
            {{ blok.text }}
        </p>
    </div>
    <!-- Block end -->
</template>
